import * as React from 'react';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { CrDropdown } from '../../components/cr/CrDropdown';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import Section from './Section';
import RecommendationsTab from './RecommendationsTab';
import ActionUpdatesTab from './ActionUpdatesTab';
import * as types from '../../types';
import * as services from '../../services';
import { IEntity, IOrgLevel1 } from '../../types';


export interface IGiaaUpdatesClsProps extends types.IBaseComponentProps {
    userContext: types.IUserContext;
}

export interface ILookupData {
    DGAreas: IOrgLevel1[];
}

export class LookupData implements ILookupData {
    public DGAreas: IOrgLevel1[] = [];

}

export interface IGiaaUpdatesClsState {
    LookupData: ILookupData;
    //PeriodId: string | number;
    DirectorateGroupId: string | number;
    IsArchivedPeriod: boolean;

    SelectedPivotKey: string;

    Section1_IsOpen: boolean;
    Section1_MainList_IncompleteOnly: boolean;
    Section1__MainList_JustMine: boolean;
    Section1_MainList_ListFilterText: string;

    Section2_IsOpen: boolean;
    Section2_MainList_IncompleteOnly: boolean;
    Section2__MainList_JustMine: boolean;
    Section2_MainList_ListFilterText: string;

    MainListsSaveCounter: number;

    //generic for both sections
    Section_MainList_SelectedId: number;
    Section_MainList_SelectedTitle: string;
    Section_MainList_FilteredItems: any[];

    //Rec Tab
    RecList_SelectedId: number;
    RecList_SelectedTitle: string;
    RecList_FilteredItems: any[];
    RecList_SelectedItem_ActionOwnerPermission: boolean;

    RecList_IncompleteOnly: boolean;
    RecList_JustMine: boolean;
    RecList_ActionStatusTypeId: number;

}
export class GiaaUpdatesClsState implements IGiaaUpdatesClsState {
    public LookupData = new LookupData();
    //public PeriodId: string | number = 0;
    public IsArchivedPeriod = false;
    public DirectorateGroupId: string | number = 0;
    public SelectedPivotKey = "Reports"; //default, 1st tab selected

    public Section1_IsOpen: boolean = true;
    public Section1_MainList_IncompleteOnly = false;
    public Section1__MainList_JustMine = false;
    public Section1_MainList_ListFilterText: string = null;
    public MainListsSaveCounter: number = 0;


    public Section2_IsOpen: boolean = false;
    public Section2_MainList_IncompleteOnly = false;
    public Section2__MainList_JustMine = false;
    public Section2_MainList_ListFilterText: string = null;

    //generic for both sections
    public Section_MainList_SelectedId: number = 0;
    public Section_MainList_SelectedTitle: string = null;
    public Section_MainList_FilteredItems = [];

    //Rec Tab
    public RecList_SelectedId: number;
    public RecList_SelectedTitle: string;
    public RecList_FilteredItems: any[];
    public RecList_SelectedItem_ActionOwnerPermission: boolean = false;

    public RecList_IncompleteOnly = false;
    public RecList_JustMine = false;
    public RecList_ActionStatusTypeId = 0;

    constructor() {

    }
}



export default class GiaaUpdatesCls extends React.Component<IGiaaUpdatesClsProps, GiaaUpdatesClsState> {


    private deirectorateGroupService: services.OrgLevel1Service = new services.OrgLevel1Service();

    private readonly headerTxt_MainTab: string = "Reports";
    private readonly headerTxt_RecommendationsTab: string = "Recommendations";
    private readonly headerTxt_ActionUpdatesTab: string = "Updates";

    constructor(props: IGiaaUpdatesClsProps, state: IGiaaUpdatesClsState) {
        super(props);
        this.state = new GiaaUpdatesClsState();
    }


    public render(): React.ReactElement<IGiaaUpdatesClsProps> {

        return (

            <Pivot onLinkClick={this.handlePivotClick} selectedKey={`${this.state.SelectedPivotKey}`}>
                <PivotItem headerText={this.headerTxt_MainTab} itemKey={this.headerTxt_MainTab}>
                    {this.renderMainTab()}
                </PivotItem>
                {this.renderRecommendationsTab()}
                {this.renderActionUpdatesTab()}

            </Pivot>
        );
    }

    private renderMainTab(): React.ReactElement<types.IWebPartComponentProps> {
        const { LookupData: lookups } = this.state;

        let dgAreasDrpOptions = lookups.DGAreas.map((dgArea) => { return { key: dgArea.ID, text: dgArea.Title }; });
        dgAreasDrpOptions = [{ key: 0, text: "All DGAreas" }, ...dgAreasDrpOptions];

        //const periodId = Number(this.state.PeriodId);
        const directorateGroupId = Number(this.state.DirectorateGroupId);

        return (
            <div>
                {/* <CrLoadingOverlayWelcome isLoading={this.state.Loading} /> */}
                <div style={{ paddingTop: "10px" }}>
                    <CrDropdown
                        //placeholder="Select an Option"
                        label="Which DGArea?"
                        options={dgAreasDrpOptions}
                        onChange={(_, v) => this.changeDropdown(v, 'DirectorateGroupId')}
                        selectedKey={this.state.DirectorateGroupId}
                    />
                    <br />

                    {//this.state.PeriodId > 0 &&
                        <div>
                            <Section
                                isArchive={false}
                                sectionTitle="Active GIAA Audit Reports"
                                dgAreaId={this.state.DirectorateGroupId}
                                onItemTitleClick={this.handleSection_MainListItemTitleClick}
                                section_IsOpen={this.state.Section1_IsOpen}
                                onSection_toggleOpen={this.handleSection1_toggleOpen}
                                justMine={this.state.Section1__MainList_JustMine}
                                incompleteOnly={this.state.Section1_MainList_IncompleteOnly}
                                listFilterText={this.state.Section1_MainList_ListFilterText}
                                onChangeFilterText={this.handleSection1_ChangeFilterText}
                                onChangeIncompleteOnly={this.handleSection1_ChangeIncompleteOnly}
                                onChangeJustMine={this.handleSection1_ChangeJustMine}
                                onMainSaved={this.handleMainFormSaved}
                                mainListsSaveCounter={this.state.MainListsSaveCounter}
                                superUserPermission={this.isSuperUser()}
                                {...this.props} />

                            <Section
                                isArchive={true}
                                sectionTitle="Archived GIAA Audit Reports"
                                dgAreaId={this.state.DirectorateGroupId}
                                onItemTitleClick={this.handleSection_MainListItemTitleClick}
                                section_IsOpen={this.state.Section2_IsOpen}
                                onSection_toggleOpen={this.handleSection2_toggleOpen}
                                justMine={this.state.Section2__MainList_JustMine}
                                incompleteOnly={this.state.Section2_MainList_IncompleteOnly}
                                listFilterText={this.state.Section2_MainList_ListFilterText}
                                onChangeFilterText={this.handleSection2_ChangeFilterText}
                                onChangeIncompleteOnly={this.handleSection2_ChangeIncompleteOnly}
                                onChangeJustMine={this.handleSection2_ChangeJustMine}
                                onMainSaved={this.handleMainFormSaved}
                                mainListsSaveCounter={this.state.MainListsSaveCounter}
                                superUserPermission={this.isSuperUser()}
                                {...this.props}
                            />
                        </div>
                    }

                </div>
            </div>
        );
    }

    private renderRecommendationsTab() {
        if (this.state.SelectedPivotKey === this.headerTxt_RecommendationsTab || this.state.SelectedPivotKey === this.headerTxt_ActionUpdatesTab) {
            return (

                <PivotItem headerText={this.headerTxt_RecommendationsTab} itemKey={this.headerTxt_RecommendationsTab}>
                    {this.renderRecommendations()}
                </PivotItem>

            );
        }
        else
            return <React.Fragment></React.Fragment>;
    }

    private renderActionUpdatesTab() {
        if (this.state.SelectedPivotKey === this.headerTxt_ActionUpdatesTab) {
            return (

                <PivotItem headerText={this.headerTxt_ActionUpdatesTab} itemKey={this.headerTxt_ActionUpdatesTab}>
                    {this.renderActionUpdates()}
                </PivotItem>

            );
        }
        else
            return <React.Fragment></React.Fragment>;
    }


    private renderRecommendations(): React.ReactElement<types.IWebPartComponentProps> {

        return (

            <RecommendationsTab
                //filteredItems={this.state.Section_MainList_FilteredItems}
                parentId={this.state.Section_MainList_SelectedId}
                //giaaPeriodId={this.state.PeriodId}
                parentTitle={this.state.Section_MainList_SelectedTitle}
                //isViewOnly={this.isViewOnlyGoForm()}
                onItemTitleClick={this.handle_RecListItemTitleClick}
                onShowList={this.handleShowMainTab}

                incompleteOnly={this.state.RecList_IncompleteOnly}
                justMine={this.state.RecList_JustMine}
                actionStatusTypeId={this.state.RecList_ActionStatusTypeId}

                onChangeIncompleteOnly={this.handleRecList_ChangeIncompleteOnly}
                onChangeJustMine={this.handleRecList_ChangeJustMine}
                onChangeActionStatusType={this.handleRecList_ChangeActionStatusType}

                superUserPermission={this.isSuperUser()}
                consumerName="GIAA Audit Reports"

                {...this.props}
            />


        );

    }

    private renderActionUpdates(): React.ReactElement<types.IWebPartComponentProps> {
        return (
            <ActionUpdatesTab
                giaaRecommendationId={this.state.RecList_SelectedId}
                giaaAuditReportId={this.state.Section_MainList_SelectedId}
                //giaaPeriodId={this.state.PeriodId}
                filteredItemsRecList={this.state.RecList_FilteredItems}
                filteredItemsMainList={this.state.Section_MainList_FilteredItems}
                onShowList={this.handleShowRecList}

                recListIncompleteOnly={this.state.RecList_IncompleteOnly}
                recListJustMine={this.state.RecList_JustMine}
                recListActionStatusTypeId={this.state.RecList_ActionStatusTypeId}

                onChangeMainListID={this.handleSection_MainListChangeSelectedID}

                superUserPermission={this.isSuperUser()}
                giaaStaffPermission={this.isGIAAStaff()}
                actionOwnerPermission={this.state.RecList_SelectedItem_ActionOwnerPermission}

                {...this.props}
            />


        );
    }




    public componentDidMount(): void {
        //this.setState({ Loading: true } );
        this.loadDGAreas();
    }


    private loadDGAreas = (): void => {
        this.deirectorateGroupService.readAll(`?$orderby=Title`).then((data: IEntity[]): IEntity[] => {
            this.setState({ LookupData: this.cloneObject(this.state.LookupData, 'DGAreas', data) });
            return data;
        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading Teams lookup data`, err.message); });
    }

    private cloneObject(obj, changeProp?, changeValue?) {
        if (changeProp)
            return { ...obj, [changeProp]: changeValue };
        return { ...obj };
    }


    // protected loadLookups(): Promise<any> {

    //     return Promise.all([
    //         //this.loadPeriods(),
    //         this.loadDGAreas(),
    //         //this.loadDefForm(),

    //     ]);
    // }



    private getCurrentUserId = (): number => {
        return this.props.userContext.UserId;
    }

    private isSuperUser(): boolean {
        return this.props.userPermissions.UserIsSystemAdmin()
            || this.props.userPermissions.UserIsGIAAActionsSuperUser();
    }

    private isGIAAStaff(): boolean {
        return this.props.userPermissions.UserIsGIAAStaff();
    }

    private changeDropdown = (option: IDropdownOption, f: string, index?: number): void => {
        this.setState({ DirectorateGroupId: option.key });
    }


    private handlePivotClick = (item: PivotItem): void => {
        //this.clearErrors();
        this.setState({ SelectedPivotKey: item.props.headerText });
    }

    //section 1 event handlers

    private handleSection_MainListItemTitleClick = (ID: number, title: string, filteredItems: any[]): void => {
        this.setState({
            SelectedPivotKey: this.headerTxt_RecommendationsTab,
            Section_MainList_SelectedId: ID,
            Section_MainList_SelectedTitle: title,
            Section_MainList_FilteredItems: filteredItems
        });
    }

    private handleSection_MainListChangeSelectedID = (ID: number): void => {
        this.setState({
            Section_MainList_SelectedId: ID,

        });
    }

    private handleSection1_toggleOpen = (): void => {
        this.setState({ Section1_IsOpen: !this.state.Section1_IsOpen });
    }

    private handleSection1_ChangeFilterText = (value: string): void => {
        this.setState({ Section1_MainList_ListFilterText: value });
    }

    private handleSection1_ChangeIncompleteOnly = (value: boolean): void => {
        this.setState({ Section1_MainList_IncompleteOnly: value });
    }

    private handleSection1_ChangeJustMine = (value: boolean): void => {
        this.setState({ Section1__MainList_JustMine: value });
    }

    private handleMainFormSaved = (): void => {
        const x: number = this.state.MainListsSaveCounter + 1;
        this.setState({ MainListsSaveCounter: x });
    }

    //section 2 event handlers
    private handleSection2_toggleOpen = (): void => {
        this.setState({ Section2_IsOpen: !this.state.Section2_IsOpen });
    }

    private handleSection2_ChangeFilterText = (value: string): void => {
        this.setState({ Section2_MainList_ListFilterText: value });
    }

    private handleSection2_ChangeIncompleteOnly = (value: boolean): void => {
        this.setState({ Section2_MainList_IncompleteOnly: value });
    }

    private handleSection2_ChangeJustMine = (value: boolean): void => {
        this.setState({ Section2__MainList_JustMine: value });
    }

    //rec event handlers
    private handleShowMainTab = (): void => {
        //this.clearErrors();
        this.setState({ SelectedPivotKey: this.headerTxt_MainTab });
    }

    private handle_RecListItemTitleClick = (ID: number, title: string, filteredItems: any[]): void => {

        const currentUderId: number = this.getCurrentUserId();
        let actionOwnerPermission: boolean = false;

        const currentRec = filteredItems.filter(x => x['ID'] === ID);
        let ownerIdsStr: string = "";
        if (currentRec.length > 0) {
            ownerIdsStr = currentRec[0]["OwnerIds"];
            const ownerIdsArr: string[] = ownerIdsStr.split(',');

            for (let i = 0; i < ownerIdsArr.length; i++) {

                let ownerId: number = Number(ownerIdsArr[i]);
                if (ownerId === currentUderId) {
                    actionOwnerPermission = true;
                    break;
                }
            }
        }

        this.setState({
            SelectedPivotKey: this.headerTxt_ActionUpdatesTab,
            RecList_SelectedId: ID,
            RecList_SelectedTitle: title,
            RecList_SelectedItem_ActionOwnerPermission: actionOwnerPermission,
            RecList_FilteredItems: filteredItems
        });
    }


    private handleRecList_ChangeIncompleteOnly = (value: boolean): void => {
        this.setState({ RecList_IncompleteOnly: value });
    }

    private handleRecList_ChangeJustMine = (value: boolean): void => {
        this.setState({ RecList_JustMine: value });
    }

    private handleRecList_ChangeActionStatusType = (option: IDropdownOption): void => {
        this.setState({ RecList_ActionStatusTypeId: Number(option.key), },);
    }

    //update (3rd tab) event handerls
    private handleShowRecList = (): void => {
        //this.clearErrors();
        this.setState({ SelectedPivotKey: this.headerTxt_RecommendationsTab });
    }

}


