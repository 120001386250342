import React, { useEffect, useState } from "react";
import { IVerticalBarChartDataPoint, VerticalBarChart } from "@fluentui/react-charting";
import { IGraphData } from "../../types";
import { ThemeProvider } from "@fluentui/react";
import { darkTheme } from "../../AppGlobals";
import useScreenSizeDetector from "../useScreenSizeDetector";


interface GrVerticalBarChartProps {
    menuData?: any[];
    graphData: IGraphData;
}

interface ChartConfig {
    Main: {
        PageSizeMobile: number;
        PageSizeMediumScreen: number;
        PageSizeLargeScreen: number;
        BarWidth: number;
        RoundCorners: boolean;
        EnableGradient: boolean;
        UseSingleColor: boolean;
        AutoBarColor: boolean;
        Colors: string;
        BarTooltip: boolean;
        ShowDescription: "left" | "right" | "top" | "bottom";
    };
    YAxis: {
        AxisTitle: string;
        QueryColumn: string;
    };
    XAxis: {
        AxisTitle: string;
        QueryColumn: string;
        WrapXAxisLables: boolean;
        XAxisInnerPadding: number,
        XAxisOuterPadding: number,
    };
}

export const GrVerticalBarChart = (props: GrVerticalBarChartProps): React.ReactElement => {
    const { isMobile, isMediumScreen, isLargeScreen } = useScreenSizeDetector();
    const width: number = 900;
    const height: number = 500;

    const [currentPage, setCurrentPage] = useState(-1);
    const [chartData, setChartData] = useState<IVerticalBarChartDataPoint[]>([]);
    const [allData, setAllData] = useState<IVerticalBarChartDataPoint[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [graphConfig, setGraphConfig] = useState<ChartConfig>();
    const [barColors, setBarColors] = useState<string[]>([]);

    //const rootStyle = { width: `${width}px`, height: `${height}px`, };
    const rootStyle = { width: `95%`, height: `${height}px`, };

    useEffect(() => {
        const adjustData = () => {
            if (graphConfig) {
                // Determine the items for the current page
                const pageSize: number = getPageSize(graphConfig);
                const startIdx = currentPage * pageSize;
                const endIdx = startIdx + pageSize;
                const currentItems = allData.slice(startIdx, endIdx);

                setChartData(currentItems);
            }
            else {

                //first time
                const jsonData = props.graphData.jSonProperties;
                //Parse the JSON string into a ChartConfig object
                const chartConfig: ChartConfig = JSON.parse(jsonData);

                const menuDataMap = new Map(props.menuData.map(item => [item.ID, item]));
                const cData: IVerticalBarChartDataPoint[] = [];

                props.graphData.data.forEach((value) => {
                    const menuItem = menuDataMap.get(value[chartConfig.XAxis.QueryColumn]); //AppID
                    if (menuItem) {
                        cData.push({
                            x: menuItem.AppName,
                            y: value[chartConfig.YAxis.QueryColumn],
                        });
                    }
                });

                const colorArray: string[] = chartConfig.Main?.Colors
                    ?.split(",")
                    .filter((color) => color.trim() !== "")
                    .map((color) => color.trim());

                const pageSize: number = getPageSize(chartConfig);
                const totPages = Math.ceil(cData.length / pageSize);
                setGraphConfig(chartConfig);
                setBarColors(colorArray);
                setAllData(cData);
                setTotalPages(totPages);
                setCurrentPage(0); //set 0 first time
            }
        };

        adjustData();
    }, [props.graphData, currentPage, totalPages]);

    useEffect(() => {
        if (graphConfig) {

            // Reset to first page when screen size changes
            setCurrentPage(0);
            // Recalculate data for the new page size
            const pageSize = getPageSize(graphConfig);
            const totPages = Math.ceil(allData.length / pageSize);
            setTotalPages(totPages);
        }
    }, [isMobile, isMediumScreen, isLargeScreen, graphConfig]);

    const getPageSize = (config: ChartConfig): number => {
        if (config) {
            if (isMobile) return config.Main.PageSizeMobile;
            else if (isMediumScreen) return config.Main.PageSizeMediumScreen;
            else return config.Main.PageSizeLargeScreen;
        }
        return 50;
    };

    const getChartWidth = (): number => {
        if (isMobile) {
            return window.innerWidth * 0.9; // 90% of the screen width on mobile
        } else if (isMediumScreen) {
            return window.innerWidth * 0.8; // 80% of the screen width on medium screens
        } else {
            return window.innerWidth * 0.7; // 70% of the screen width on large screens
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (

        <div style={rootStyle}>
            <ThemeProvider>
                {graphConfig &&
                    <>
                        <div className="chart-nav-buttons-container">
                            <button
                                onClick={handlePrevious}
                                className="chart-button previous-chart-button"
                                style={{ visibility: currentPage === 0 ? 'hidden' : 'visible' }}
                            >
                                &#8592;
                            </button>
                            <button
                                onClick={handleNext}
                                className="chart-button next-chart-button"
                                style={{ visibility: currentPage >= totalPages - 1 ? 'hidden' : 'visible' }}
                            >
                                &#8594;
                            </button>
                        </div>

                        <VerticalBarChart
                            chartTitle={props.graphData.title}
                            data={chartData}
                            hideLegend={true}
                            height={height}
                            //width={width}
                            width={getChartWidth()}
                            maxBarWidth={1000}
                            barWidth={graphConfig.Main.BarWidth ?? 'auto'}
                            roundCorners={graphConfig.Main.RoundCorners ?? false}
                            enableGradient={graphConfig.Main.EnableGradient ?? false}
                            useSingleColor={graphConfig.Main.UseSingleColor ?? false}
                            {...(barColors.length > 0 && { colors: barColors })}
                            hideTooltip={!graphConfig.Main.BarTooltip ?? true}
                            showXAxisLablesTooltip={!graphConfig.XAxis.WrapXAxisLables ?? false}
                            wrapXAxisLables={graphConfig.XAxis.WrapXAxisLables ?? true}
                            enableReflow={true}
                            xAxisTitle={graphConfig.XAxis.AxisTitle}
                            yAxisTitle={graphConfig.YAxis.AxisTitle}
                            xAxisInnerPadding={graphConfig.XAxis.XAxisInnerPadding ?? undefined}
                            xAxisOuterPadding={graphConfig.XAxis.XAxisOuterPadding ?? undefined}
                        />
                    </>
                }

            </ThemeProvider>
        </div>

    );
};

