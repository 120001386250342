import * as React from 'react';
import * as types from '../../types';
import * as services from '../../services';
import RecommendationSaveForm from './RecommendationSaveForm';
import { FilteredRecList } from './FilteredRecList';
import { IEntity } from '../../types';
import { IUpdatesListColumn, ColumnDisplayTypes } from '../../types/UpdatesListColumn';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { IObjectWithKey, Selection } from '@fluentui/react/lib/DetailsList';
import { ConfirmDialog } from '../cr/ConfirmDialog';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import styles from '../../styles/cr.module.scss';
import withScreenSizeDetector from '../withScreenSizeDetector';
import { ScreenSize } from '../useScreenSizeDetector';

export interface IRecommendationsListProps extends types.IBaseComponentProps {
    onItemTitleClick: (ID: number, title: string, filteredItems: any[]) => void;
    giaaAuditReportId: number | string;
    incompleteOnly: boolean;
    onChangeIncompleteOnly: (value: boolean) => void;
    justMine: boolean;
    onChangeJustMine: (value: boolean) => void;
    actionStatusTypeId: number;
    onChangeActionStatusType: (option: IDropdownOption) => void;
    filterText?: string;
    onChangeFilterText: (value: string) => void;
    actionStatusTypes: IEntity[];
    superUserPermission: boolean;
    screenSize: ScreenSize
}

export interface IRecommendationsListState<T> {
    SelectedEntity: number;
    SelectedEntityTitle: string;
    SelectedEntityChildren: number;
    ShowForm: boolean;
    EnableEdit?: boolean;
    EnableDelete?: boolean;
    HideDeleteDialog: boolean;
    ShowChildForm: boolean;
    CurrentPage?: number;
    NextPageAvailable?: boolean;
    Entities: T[];
    Loading: boolean;
    ListFilterText?: string;
    InitDataLoaded: boolean;
}

export class RecommendationsListState<T> implements IRecommendationsListState<T> {
    public SelectedEntity = null;
    public SelectedEntityTitle: string = null;
    public SelectedEntityChildren = null;
    public ShowForm = false;
    public HideDeleteDialog = true;
    public EnableEdit = false;
    public EnableDelete = false;
    public ShowChildForm = false;
    public CurrentPage = 1;
    public NextPageAvailable = false;
    public Entities: T[] = [];
    public Loading = false;
    public ListFilterText = null;
    public InitDataLoaded = false;
}

class RecommendationsList extends React.Component<IRecommendationsListProps, IRecommendationsListState<IEntity>> {
    private _selection: Selection;
    private recService: services.GIAARecommendationService = new services.GIAARecommendationService();

    private listColumns: IUpdatesListColumn[] = [
        {
            key: 'ID',
            name: 'ID',
            fieldName: 'ID',
            minWidth: 1,
            isResizable: true,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },
        {
            key: 'Title',
            name: 'Ref',
            fieldName: 'Title',
            minWidth: 90,
            maxWidth: 90,
            isResizable: true,
            isMultiline: true,
            headerClassName: styles.bold,
        },
        {
            key: 'RecommendationDetails',
            name: 'Recommendations/Actions',
            fieldName: 'RecommendationDetails',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            isMultiline: true,
            //isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'Priority',
            name: 'Priority',
            fieldName: 'Priority',
            minWidth: 65,
            maxWidth: 65,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'TargetDate',
            name: 'Original Date',
            fieldName: 'TargetDate',
            minWidth: 98,
            maxWidth: 98,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },

        {
            key: 'RevisedDate',
            name: 'Revised Date',
            fieldName: 'RevisedDate',
            minWidth: 98,
            maxWidth: 98,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'ActionStatus',
            name: 'Action Status',
            fieldName: 'ActionStatus',
            minWidth: 98,
            maxWidth: 98,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },

        {
            key: 'UpdateStatus',
            name: 'Update Status',
            fieldName: 'UpdateStatus',
            minWidth: 98,
            maxWidth: 98,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },

        {
            key: 'Owners',
            name: 'Owners',
            fieldName: 'Owners',
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            isResizable: true,
            isCollapsible: true,
            headerClassName: styles.bold,
        },
        {
            key: 'OwnerIds',
            name: 'OwnerIds',
            fieldName: 'OwnerIds',
            minWidth: 1,
            maxWidth: 1,
            headerClassName: styles.bold,
            columnDisplayType: ColumnDisplayTypes.Hidden,
        },
    ];

    constructor(props: IRecommendationsListProps, state: IRecommendationsListState<IEntity>) {
        super(props);
        this.state = new RecommendationsListState<IEntity>();

        this._selection = new Selection({
            onSelectionChanged: () => {
                if (this._selection.getSelectedCount() === 1) {
                    const sel = this._selection.getSelection()[0];
                    const key = Number(sel.key);
                    const title: string = sel["Title"];
                    this.setState({ SelectedEntity: key, SelectedEntityTitle: title, EnableEdit: true, EnableDelete: true });
                }
                else {
                    this.setState({ SelectedEntity: null, SelectedEntityTitle: null, EnableEdit: false, EnableDelete: false });
                }
            }
        });
    }

    public render(): React.ReactElement<IRecommendationsListProps> {

        return (
            <div className={`${styles.cr}`}>
                <div style={{ position: 'relative' }}>
                    <CrLoadingOverlay isLoading={this.state.Loading} />
                    {this.renderList()}
                    {this.state.ShowForm && this.renderForm()}

                    <ConfirmDialog hidden={this.state.HideDeleteDialog} title={`Are you sure you want to delete ${this.getSelectedEntityName()}?`} content={`Please note, all updates and related information will also be deleted.`} confirmButtonText="Delete" handleConfirm={this.deleteRecord} handleCancel={this.toggleDeleteConfirm} />
                </div>
            </div>
        );
    }

    private adjustColumnWidthsForScreenSize(columns: IUpdatesListColumn[]): IUpdatesListColumn[] {
        const { isMediumScreen, isLargeScreen, isMobile } = this.props.screenSize;

        // Create a new copy of the columns array
        return columns.map((column) => {
            let adjustedColumn = { ...column };

            // Adjust widths based on screen size
            if (isMediumScreen) {
                if (column.key === 'RecommendationDetails') {
                    adjustedColumn.minWidth = 200;
                    adjustedColumn.maxWidth = 200;
                }
                if (column.key === 'Priority') {
                    adjustedColumn.minWidth = 50;
                    adjustedColumn.maxWidth = 50;
                }
                if (column.key === 'TargetDate') {
                    adjustedColumn.name = "Orig Date";
                    adjustedColumn.minWidth = 65;
                    adjustedColumn.maxWidth = 65;
                }
                if (column.key === 'RevisedDate') {
                    adjustedColumn.name = "Rev Date";
                    adjustedColumn.minWidth = 65;
                    adjustedColumn.maxWidth = 65;
                }
                if (column.key === 'ActionStatus') {
                    adjustedColumn.minWidth = 85;
                    adjustedColumn.maxWidth = 85;
                }

            } else if (isMobile) {
                if (column.key === 'Title') {
                    adjustedColumn.minWidth = 200;
                    adjustedColumn.maxWidth = 300;
                }
            } else if (isLargeScreen) {
                // Revert to default for large screens
                if (column.key === 'RecommendationDetails') {
                    adjustedColumn.minWidth = 200;
                    adjustedColumn.maxWidth = 300;
                }
                if (column.key === 'Priority') {
                    adjustedColumn.minWidth = 65;
                    adjustedColumn.maxWidth = 65;
                }
                if (column.key === 'TargetDate') {
                    adjustedColumn.name = "Original Date";
                    adjustedColumn.minWidth = 98;
                    adjustedColumn.maxWidth = 98;
                }
                if (column.key === 'RevisedDate') {
                    adjustedColumn.name = "Revised Date";
                    adjustedColumn.minWidth = 98;
                    adjustedColumn.maxWidth = 98;
                }
                if (column.key === 'ActionStatus') {
                    adjustedColumn.minWidth = 98;
                    adjustedColumn.maxWidth = 98;
                }
            }

            return adjustedColumn;
        });
    }

    private renderList() {
        const listColumns = this.getColumns();
        const listColumnsForData = this.getColumnsForData();

        const adjustedListColumns = this.adjustColumnWidthsForScreenSize(listColumns);

        let items: IObjectWithKey[] = this.state.Entities.map((e) => { return this.makeItem(e, listColumnsForData); });

        return (
            <FilteredRecList
                onItemTitleClick={this.props.onItemTitleClick}
                columns={adjustedListColumns}
                items={items}
                incompleteOnly={this.props.incompleteOnly}
                onChangeIncompleteOnly={this.props.onChangeIncompleteOnly}
                justMine={this.props.justMine}
                onChangeJustMine={this.props.onChangeJustMine}
                actionStatusTypeId={this.props.actionStatusTypeId}
                onChangeActionStatusType={this.props.onChangeActionStatusType}
                filterText={this.props.filterText}
                onFilterChange={this.props.onChangeFilterText}
                selection={this._selection}
                onAdd={this.addItem}
                onEdit={this.editItem}
                onDelete={this.checkDelete}
                editDisabled={!this.state.EnableEdit}
                deleteDisabled={!this.state.EnableDelete}
                actionStatusTypes={this.props.actionStatusTypes}
                superUserPermission={this.props.superUserPermission}
            />
        );
    }

    private renderForm() {
        return (
            <RecommendationSaveForm
                giaaAuditReportId={this.props.giaaAuditReportId}
                showForm={this.state.ShowForm}
                entityId={this.state.SelectedEntity}
                onSaved={this.formSaved}
                onCancelled={this.closePanel}
                {...this.props}
            />
        );

    }

    private makeItem = (e: IEntity, listColumns: IUpdatesListColumn[]): any => {
        let item: any = { key: e["ID"] };
        listColumns.map((c) => {
            let fieldContent: string = String(e[c.fieldName]);
            item = {
                [c.fieldName]: fieldContent,
                ...item
            };
        });
        return item;
    }

    private getColumns(): IUpdatesListColumn[] {
        let listColumns: IUpdatesListColumn[];
        listColumns = this.listColumns.filter(c => c.columnDisplayType !== ColumnDisplayTypes.Hidden);
        return listColumns;
    }

    private getColumnsForData(): IUpdatesListColumn[] {
        //separate method for data because we want to add Hidden Columns in the data, so hidden columns data can be filtered
        const listColumns: IUpdatesListColumn[] = this.listColumns;
        return listColumns;
    }

    private closePanel = (): void => {
        this.setState({ ShowForm: false });
    }

    private formSaved = (): void => {
        this.loadData();
        this.closePanel();
    }

    private getSelectedEntityName = (): string => {
        let entity = this.state.Entities.filter((e) => { return e.ID === this.state.SelectedEntity; });
        return entity[0] ? entity[0].Title : null;
    }

    private toggleDeleteConfirm = (): void => {
        this.setState({ HideDeleteDialog: !this.state.HideDeleteDialog });
    }

    private deleteRecord = (): void => {
        this.setState({ HideDeleteDialog: true });
        if (this.state.SelectedEntity) {
            this.recService.delete(this.state.SelectedEntity).then(this.loadData, (err) => {
                if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error deleting item ${this.state.SelectedEntity}`, err.message);
            });
        }
    }

    private loadData = (): void => {
        this.setState({ Loading: true });
        const read: Promise<IEntity[]> = this.recService.readAllWithFilters(this.props.giaaAuditReportId, this.props.incompleteOnly, this.props.justMine, this.props.actionStatusTypeId);
        read.then((entities: any): void => {
            console.log('rec list data', entities);
            this.setState({
                Loading: false, Entities: entities,
            });

        }, (err) => this.errorLoadingData(err));
    }

    private errorLoadingData = (err: any, entityName?: string): void => {
        this.setState({ Loading: false });
        if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading ${entityName || 'items'}`, err.message);
    }

    public componentDidMount(): void {
        this.loadData();
    }

    public componentDidUpdate(prevProps: IRecommendationsListProps): void {
        if (prevProps.actionStatusTypeId !== this.props.actionStatusTypeId || prevProps.justMine !== this.props.justMine || prevProps.incompleteOnly !== this.props.incompleteOnly) {
            this._selection.setAllSelected(false);
            this.loadData();
        }
    }

    private addItem = (): void => {
        if (this.state.SelectedEntity)
            this._selection.setKeySelected(this.state.SelectedEntity.toString(), false, false);
        this.setState({ SelectedEntity: null, ShowForm: true });
    }

    private editItem = (): void => {
        this.setState({ ShowForm: true });
    }

    private checkDelete = (): void => {

        this.toggleDeleteConfirm();

    }
}

export default withScreenSizeDetector(RecommendationsList);