import React, { useEffect, useState } from "react";
import { DonutChart, IChartDataPoint, IChartProps } from "@fluentui/react-charting";
import { IGraphData } from "../../types";
import { ThemeProvider } from "@fluentui/react";
import useScreenSizeDetector from "../useScreenSizeDetector";

interface GrDonutChartProps {
    graphData: IGraphData;
    containerWidth?: string;
    containerHeight?: string;
}

interface ChartConfig {
    Main: {
        DataColumn: string;
        LegendColumn: string;
        ColorColumn: string;
        Color2Column: string;
        ValueInsideDonut: string | number;
        HideLabels: boolean;
        ShowLabelsInPercent: boolean;
        InnerRadius: number;
        ChartWidth: number;
        ChartHeight: number;
        RoundCorners: boolean;
        EnableGradient: boolean;
    };
}

export const GrDonutChart = (props: GrDonutChartProps): React.ReactElement => {
    
    const { isMobile, isMediumScreen, isLargeScreen } = useScreenSizeDetector();
    const width: string = props.containerWidth ?? '100%'; // Change to 100% for full width
    const height: string = props.containerHeight ?? '100%'; // Full height

    const [chartData, setChartData] = useState<IChartProps>();
    const [graphConfig, setGraphConfig] = useState<ChartConfig>();

    const rootStyle = {
        width: width,
        height: height,
        display: 'flex',  // Ensure the chart container is using flexbox
        justifyContent: 'center',  // Center horizontally
        //alignItems: 'center',  // Center vertically
    };

    useEffect(() => {
        const adjustData = () => {
            //console.log(props.graphData);
            const jsonData = props.graphData.jSonProperties;
            const chartConfig: ChartConfig = JSON.parse(jsonData);

            const cData: IChartDataPoint[] = [];
            props.graphData.data.forEach((value) => {
                let color: string | undefined = undefined;
                let gradient: [string, string] | undefined = undefined;
                if (value[chartConfig.Main.ColorColumn]?.trim() !== '') {
                    color = value[chartConfig.Main.ColorColumn];
                    gradient = [color, value[chartConfig.Main.Color2Column]];
                }

                cData.push({
                    legend: value[chartConfig.Main.LegendColumn],
                    data: value[chartConfig.Main.DataColumn],
                    color: color,
                    gradient: gradient,
                });
            });

            setGraphConfig(chartConfig);

            const data: IChartProps = {
                chartTitle: props.graphData.title,
                chartData: cData,
            };

            setChartData(data);
        };

        adjustData();
    }, [props.graphData]);

    return (
        <div style={rootStyle}>
            <ThemeProvider>
                {graphConfig && chartData && (
                    <DonutChart
                        culture={window.navigator.language}
                        data={chartData}
                        hideLabels={graphConfig.Main.HideLabels}
                        showLabelsInPercent={graphConfig.Main.ShowLabelsInPercent}
                        innerRadius={graphConfig.Main.InnerRadius}
                        hideLegend={false}
                        height={graphConfig.Main.ChartHeight}
                        width={graphConfig.Main.ChartWidth}
                        valueInsideDonut={graphConfig.Main.ValueInsideDonut}
                        roundCorners={graphConfig.Main.RoundCorners}
                        {...(!isMobile && { enableGradient: graphConfig.Main.EnableGradient })}
                    />
                )}
            </ThemeProvider>
        </div>
    );
};


